import React from 'react';

import './styles.scss';

// markup
const NotFoundPage = () => {
  return (
    <></>

  );
};

export default NotFoundPage;
